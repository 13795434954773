






































import { Home } from "@/router";

import Component from "vue-class-component";
import { Store } from "@/store/store";

import GenericForm from '@/components/forms/GenericForm.vue'

import AccountDetails from "../components/account_details.vue";
import BusinessBranding from "../components/business_branding.vue";
import TrialUsers from "../components/trial_users.vue";

interface Customer {
  companyId: string;
  method: string;
  businessProfileBusinessName: string;
  businessProfileLeadContact: string;
  businessProfileEmail: string;
  businessProfileWebAddress: string;
  businessProfileAddressLine1: string;
  businessProfileAddressLine2: string;
  businessProfileTown: string;
  businessProfileCountyState: string;
  businessProfilePostcode: string;
  businessProfileCountry: string;

  businessBrandGuestWelcomeMessage?: string;
  businessBrandPrimaryColorHex?: string;
  businessBrandSecondaryColorHex?: string;
  businessBrandFaviconUrl?: string;

  businessBrandLogo?: string;
  businessBrandHeroImage?: string;
  businessBrandFavicon?: string;
}

interface CustomerUsers {
  activated_at?: string;
  addons?: Array<any>;
  billing_plan?: any;
  created_at?: string;
  email?: string;
  id?: number;
  is_pending?: boolean;
  last_name?: string;
  meeting_room_alias?: string;
  name?: string;
  role?: string;
  length?: number;
}

interface NewEmail {
  email: string;
  alias?: string;
  is_admin: boolean;
}

interface Errors {
  profileErrorMessage: string,
  businessProfileBusinessName: Array<string>;
  businessProfileLeadContact: Array<string>;
  businessProfileEmail: Array<string>;
  businessProfileWebAddress: Array<string>;
  businessProfileAddressLine1: Array<string>;
  businessProfileAddressLine2: Array<string>;
  businessProfileTown: Array<string>;
  businessProfileCountyState: Array<string>;
  businessProfilePostcode: Array<string>;
  businessProfileCountry: Array<string>;
  businessBrandPrimaryColorHex: Array<string>;
  businessBrandSecondaryColorHex: Array<string>;
  businessBrandLogo: Array<string>;
  businessBrandHero: Array<string>;
  businessBrandFavicon: Array<string>;
  businessBrandFaviconUrl: Array<string>;
  businessTrialUser1Email: Array<string>;
  businessTrialUser2Email: Array<string>;
  businessTrialUser3Email: Array<string>;
  businessTrialUser1Alias: Array<string>;
  businessTrialUser2Alias: Array<string>;
  businessTrialUser3Alias: Array<string>;
}

@Component({
  components: {
    GenericForm,
    AccountDetails,
    BusinessBranding,
    TrialUsers,
  },
  metaInfo: {
    title: "Create Customer Account"
  }
})
export default class CreateAccount extends Store {
  componentTitle = "Create Customer Account";
  customerID = "";
  customerAccount: any = {};
  customerBrand: any = {}
  customerUsers: Array<CustomerUsers> = [];
  newCustomerUsers: Array<NewEmail> = [
    {
      email: "",
      alias: "",
      is_admin: true
    },
    {
      email: "",
      alias: "",
      is_admin: false
    },
    {
      email: "",
      alias: "",
      is_admin: false
    }
  ];

  errors: Errors = {
    profileErrorMessage: '',
    businessProfileBusinessName: [],
    businessProfileLeadContact: [],
    businessProfileEmail: [],
    businessProfileWebAddress: [],
    businessProfileAddressLine1: [],
    businessProfileAddressLine2: [],
    businessProfileTown: [],
    businessProfileCountyState: [],
    businessProfilePostcode: [],
    businessProfileCountry: [],
    businessBrandPrimaryColorHex: [],
    businessBrandSecondaryColorHex: [],
    businessBrandLogo: [],
    businessBrandHero: [],
    businessBrandFavicon: [],
    businessBrandFaviconUrl: [],
    businessTrialUser1Email: [],
    businessTrialUser2Email: [],
    businessTrialUser3Email: [],
    businessTrialUser1Alias: [],
    businessTrialUser2Alias: [],
    businessTrialUser3Alias: [],
  }

  Home = Home;

  updateCustomer(data: any) {
    this.customerAccount = data
  }

  updateBranding(data: any) {
    this.customerBrand = data
  }

  updateUsers(data: any) {
    this.newCustomerUsers = data
  }

  // API Calls
  createNewCustomerAccount() {
    this.createCustomerAccount({
      setCompany: {
        business_profile: this.customerAccount,
        business_brand: this.customerBrand,
        users: this.newCustomerUsers
      }
    })
      .then(() => {
        this.$toast.success("Created Customer Account");
        this.$router.push(Home);
      })
      .catch((res) => {
        this.errors = res
        this.$toast.error("Failed To Create Customer Account");
      });
  }
}
